// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';
import { LutFilter } from './lut_filter';


const fragmentSource = `
  precision mediump float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  void main()
  {           
    vec4 x, y;
    
    vec4 color = texture2D(uTexture, vTexCoord);
    x = color;
      
    vec3 Pr = vec3(-0.162626744186047, 1.161626744186050, 0.0);
    vec3 Pg = vec3(0.155320235420426, 0.338465799502559, 0.505213965077015);
    vec3 Pb = vec3(0.191038610598540, 1.517158127718780, -0.709196738317319);
    

    y.r = Pr.x+x.r*Pr.y;
    y.g = Pg.x+x.g*(Pg.y+x.g*Pg.z);
    y.b = Pb.x+x.b*(Pb.y+x.b*Pb.z);
    y.w = x.w;
    
    if( x.r < 0.14)
    {
        y.r = 0.0;
    }
    
    gl_FragColor = y;
  }
`

type SaxFlashFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: SaxFlashFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class SaxFlashFilter extends filters.BaseFilter<'SaxFlashFilter', SaxFlashFilterProps> {

  static type = 'SaxFlashFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "/LUT/HALD64_SSM_Sax_Flash.png";

  declare lut: SaxFlashFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: SaxFlashFilterProps = filterDefaultValues) {
    super(options);

    if (SaxFlashFilter.lutImageData) {
      this.lut = SaxFlashFilter.lutImageData;
    } else {
      throw new Error(
        'LUT is not loaded. Use SaxFlashFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of SaxFlashFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<SaxFlashFilter> {
    // Load LUT if not already loaded
    if (!this.lutImageData) {
      const lutImage = await FabricImage.fromURL(SaxFlashFilter.HALD_URL);
      this.lutImageData = LutFilter.fabricImageToImageData(lutImage);
      console.log('Sax Flash LUT loaded successfully.');
    }

    // Create and return an instance of the filter
    return new SaxFlashFilter();
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }

  /**
   * Apply the SaxFlashFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    LutFilter.applyHaldLut(pixels, this.lut, SaxFlashFilter.HALD_URL);
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }
}
