// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';
import { LutFilter } from './lut_filter';


const fragmentSource = `
  precision mediump float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  void main()
  {           
    vec4 x, y;
    
    vec4 color = texture2D(uTexture, vTexCoord);
    x = color;
      
    vec3 Pr = vec3(-0.049428327323122, 0.889984252865947, 0.158444074457175);
    vec3 Pg = vec3(1.0, 0.0, 0.0);
    vec3 Pb = vec3(0.220000000000000, 0.925731056991487, 0.076385937827833);

    float tmp = 0.2989 * x.r + 0.5870 * x.g + 0.1140 * x.b;

    y.r = Pr.x + tmp * (Pr.y+tmp * Pr.z);
    y.g = tmp * Pg.x;
    y.b = Pb.x + tmp * (Pb.y + tmp * Pb.z);
    y.w = x.w;

    if(tmp < 0.055)
    {
      y.r = 0.0;
    }
    if(tmp > 0.79)
    {
      y.b = 1.0;
    }

    gl_FragColor = y;
  }
 `

type PurpleToneFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: PurpleToneFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class PurpleToneFilter extends filters.BaseFilter<'PurpleToneFilter', PurpleToneFilterProps> {

  static type = 'PurpleToneFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "/LUT/HALD64_Purple_Tone.png";

  declare lut: PurpleToneFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: PurpleToneFilterProps = filterDefaultValues) {
    super(options);

    if (PurpleToneFilter.lutImageData) {
      this.lut = PurpleToneFilter.lutImageData;
    } else {
      throw new Error(
        'LUT is not loaded. Use PurpleToneFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of PurpleToneFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<PurpleToneFilter> {
    // Load LUT if not already loaded
    if (!this.lutImageData) {
      const lutImage = await FabricImage.fromURL(PurpleToneFilter.HALD_URL);
      this.lutImageData = LutFilter.fabricImageToImageData(lutImage);
      console.log('Purple Tone LUT loaded successfully.');
    }

    // Create and return an instance of the filter
    return new PurpleToneFilter();
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }

  /**
   * Apply the PurpleToneFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    LutFilter.applyHaldLut(pixels, this.lut, PurpleToneFilter.HALD_URL);
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }
}
