// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';
import { LutFilter } from './lut_filter';

const fragmentSource = `
 precision mediump float;
 uniform sampler2D uTexture;
 varying vec2 vTexCoord;
 void main() {
    vec4 x, y;
    vec4 color = texture2D(uTexture, vTexCoord);
    
    x = color;
    
    vec3 Pr = vec3(-0.490926254677118, 2.521996012364260, -0.304324179012426);
    vec3 Pg = vec3(-0.636969848869374, 3.769836874091810, -2.170414050085010);
    vec3 Pb = vec3(-0.022014196241998, 1.507546962656000, -0.630591442410286);
    
    y.r = Pr.x + x.r * (Pr.y + x.r * Pr.z);
    y.g = Pg.x + x.g * (Pg.y + x.g * Pg.z);
    y.b = Pb.x + x.b * (Pb.y + x.b * Pb.z);
    y.w = x.w;
    
    if (x.r < 0.20) {
        y.r = 0.0013;
    }
    if (x.r > 0.64) {
        y.r = 0.9985;
    }
    if (x.g < 0.19) {
        y.g = 0.00095;
    }
    if (x.g > 0.89) {
        y.g = 0.9990;
    }
    if (x.b < 0.185) {
        y.b = 0.2353;
    }
    if (x.b > 0.90) {
        y.b = 0.8240;
    }
    
    gl_FragColor = y;
 }`;

type PowerSaxFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: PowerSaxFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class PowerSaxFilter extends filters.BaseFilter<'PowerSaxFilter', PowerSaxFilterProps> {

  static type = 'PowerSaxFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "/LUT/HALD64_SSM_Power_Sax.png";

  declare lut: PowerSaxFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: PowerSaxFilterProps = filterDefaultValues) {
    super(options);

    if (PowerSaxFilter.lutImageData) {
      this.lut = PowerSaxFilter.lutImageData;
    } else {
      throw new Error(
        'LUT is not loaded. Use PowerSaxFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of PowerSaxFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<PowerSaxFilter> {
    // Load LUT if not already loaded
    if (!this.lutImageData) {
      const lutImage = await FabricImage.fromURL(PowerSaxFilter.HALD_URL);
      this.lutImageData = LutFilter.fabricImageToImageData(lutImage);
      console.log('Power Sax LUT loaded successfully.');
    }

    // Create and return an instance of the filter
    return new PowerSaxFilter();
  }


  protected getFragmentSource(): string {
    return fragmentSource;
  }

  // applyTo(options: T2DPipelineState) {
  //   if (options.webgl) {
  //     const gl = options.filterBackend.gl;
  //     console.log()
  //     const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
  //     const width = options.originalWidth;
  //     const height = options.originalHeight;
  //     console.log(maxTextureSize)

  //     if (width > maxTextureSize || height > maxTextureSize) {
  //       console.warn(
  //         `Image dimensions (${width}x${height}) exceed WebGL max texture size (${maxTextureSize}). Falling back to 2D.`
  //       );
  //       console.log(options)
  //       this.applyTo2d(options);
  //     } else {
  //       super.applyToWebGL(options);
  //     }
  //   } else {
  //     this.applyTo2d(options);
  //   }
  // }

  /**
   * Apply the PowerSaxFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    LutFilter.applyHaldLut(pixels, this.lut, PowerSaxFilter.HALD_URL);
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
    // gl.uniform1f(uniformLocations.uLut, this.lut);
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }
}
