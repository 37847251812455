// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';

const fragmentSource = "";

// const fragmentSource = `
//   precision mediump float;
//   uniform sampler2D uTexture;
//   uniform sampler2D uLut;
//   varying vec2 vTexCoord;

//   void main() {
//       float clutSize = 25.0;  // HALD CLUT grid size (25x25x25)
//       float rows = 5.0;
//       float cols = 5.0;

//       float delta = 255.0 / 25.0;
//       float rowRange = 255.0 / 5.0;

//       float cellWidth = 25.0;
//       float cellHeight = 5.0;

//       float leftSideLength = cellHeight * clutSize;

//       // Read the input color from the image
//       vec4 color = texture2D(uTexture, vTexCoord);

//       color *= 255.0;

//       float r = color.r;
//       float g = color.g;
//       float b = color.b;

//       float blueBandNumber = floor(b / delta);
      
//       float redCellOffset = ceil(r / delta);

//       float greenCol = floor(g / delta);
//       greenCol = mod(greenCol, cols);

//       float greenCellRow = clamp(ceil(g / rowRange), 0.0, 5.0);

//       // Calculate the x and y components separately for clarity
//       float baseUV_x = greenCol * cellWidth + redCellOffset + 0.5;
//       float baseUV_y = blueBandNumber * cellHeight + greenCellRow + 0.5;

//       // Combine into a vec2
//       vec2 baseUV = vec2(baseUV_x, baseUV_y);

//       baseUV /= 255.0;

//       // Sample the nearest color in the LUT
//       vec4 correctedColor = texture2D(uLut, baseUV);
//       correctedColor.w = color.w;

//       // Output the transformed color
//       gl_FragColor = correctedColor;
//   }
// `;

type LutFilterProps = {
  lutUrl: String;
  lut: ImageData | null;
};

const filterDefaultValues: LutFilterProps = {
  lutUrl: null,
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export class LutFilter extends filters.BaseFilter<'LutFilter', LutFilterProps> {

  static type = 'LutFilter';

  static lutImageData: ImageData | null = null;

  declare lutUrl?: LutFilterProps['lutUrl'];

  declare lut: LutFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: LutFilterProps = filterDefaultValues) {
    super(options);

    if (!this.lut) {
      throw new Error(
        'LUT is not loaded. Use LutFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of LutFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(lutUrl: string): Promise<LutFilter> {
    const lutImage = await FabricImage.fromURL(lutUrl);
    const lutImageData = this.fabricImageToImageData(lutImage);

    console.log(`LUT loaded from: ${lutUrl}`);

    return new LutFilter({ lutUrl, lut: lutImageData });
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }


  static applyHaldLut(imagePixels: Uint8ClampedArray, lut: ImageData, lutUrl: string, clutSize: number = null) {
    console.log(`LutFilter.applyHaldLut - ${lutUrl}`);
    const _lut = lut.data;

    const match = lutUrl.match(/HALD(\d+)/); // Look for "HALD" followed by digits, e.g. "/LUT/HALD64_SSM_Sax_Party.png";
    clutSize = clutSize ?? (match ? parseInt(match[1]) : 0);

    const rows = Math.sqrt(clutSize),
      cols = Math.sqrt(clutSize),
      delta = 255 / clutSize,
      cellWidth = clutSize,
      cellHeight = Math.sqrt(clutSize),
      sideLength = cols * cellWidth,
      pixelSize = 4,
      blueBandArea = cellHeight * sideLength;

    let HALD_GREEN_BOUNDARIES = [];

    const HALD_25_GREEN_BOUNDARIES = [  
      0, 11, 21, 32, 43,
      53, 64, 74, 85, 96,
      106, 117, 128, 138, 149,
      159, 170, 181, 191, 202,
      213, 223, 234, 244, 255
    ];

    const HALD_64_GREEN_BOUNDARIES = [
      0, 4, 8, 12, 16, 20, 24, 28,
      32, 36, 40, 45, 49, 53, 57, 61,
      65, 69, 73, 77, 81, 85, 89, 93,
      97, 101, 105, 109, 113, 117, 121, 125,
      130, 134, 138, 142, 146, 150, 154, 158,
      162, 166, 170, 174, 178, 182, 186, 190,
      194, 198, 202, 206, 210, 215, 219, 223,
      227, 231, 235, 239, 243, 247, 251, 255
    ];

    const HALD_144_GREEN_BOUNDARIES = [
      0, 2, 4, 5, 7, 9, 11, 12, 14, 16, 18, 20,
      21, 23, 25, 27, 29, 30, 32, 34, 36, 37, 39, 41,
      43, 45, 46, 48, 50, 52, 53, 55, 57, 59, 61, 62,
      64, 66, 68, 70, 71, 73, 75, 77, 78, 80, 82, 84,
      86, 87, 89, 91, 93, 95, 96, 98, 100, 102, 103, 105,
      107, 109, 111, 112, 114, 116, 118, 119, 121, 123, 125, 127,
      128, 130, 132, 134, 136, 137, 139, 141, 143, 144, 146, 148,
      150, 152, 153, 155, 157, 159, 160, 162, 164, 166, 168, 169,
      171, 173, 175, 177, 178, 180, 182, 184, 185, 187, 189, 191,
      193, 194, 196, 198, 200, 202, 203, 205, 207, 209, 210, 212,
      214, 216, 218, 219, 221, 223, 225, 226, 228, 230, 232, 234,
      235, 237, 239, 241, 243, 244, 246, 248, 250, 251, 253, 255
    ];

    if (clutSize == 25) {
      HALD_GREEN_BOUNDARIES = HALD_25_GREEN_BOUNDARIES;
    } else if (clutSize == 64) {
      HALD_GREEN_BOUNDARIES = HALD_64_GREEN_BOUNDARIES;
    } else if(clutSize == 144) {
      HALD_GREEN_BOUNDARIES = HALD_144_GREEN_BOUNDARIES;
    }

    for (let i = 0; i < imagePixels.length; i += 4) { 
      const r = imagePixels[i    ];
      const g = imagePixels[i + 1];
      const b = imagePixels[i + 2]; 

      const blueBandNumber = Math.ceil(b / delta);
      const blueOffset = clamp((blueBandNumber - 1), 0, clutSize - 1) * blueBandArea;
      
      let gridCol = cols;
      let greenCellRow = rows;
      for (let i = 0; i < HALD_GREEN_BOUNDARIES.length; i++) {
        if (g == 0) {
          gridCol = greenCellRow = 1;
          break;
        }
        if (g < HALD_GREEN_BOUNDARIES[i]) {
          gridCol = (i % cols) || cols;
          greenCellRow = Math.ceil(i / rows) || 1;
          break;
        }
      }
      const greenOffset = (greenCellRow > 0) ? (greenCellRow - 1) * sideLength : 0;

      const redCellOffset = Math.floor(r / delta);
      const redOffset = (gridCol - 1) * cellWidth + ((redCellOffset == cellWidth) ? cellWidth-1 : redCellOffset);

      const lutIndex = (blueOffset + greenOffset + redOffset) * pixelSize;

      imagePixels[i    ] = _lut[lutIndex    ];
      imagePixels[i + 1] = _lut[lutIndex + 1];
      imagePixels[i + 2] = _lut[lutIndex + 2];
    }
  }


  /**
   * Apply the LutFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    console.log('LutFilter.applyTo2d');
    LutFilter.applyHaldLut(pixels, this.lut, this.lutUrl);
  }


  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(gl: WebGLRenderingContext, uniformLocations: TWebGLUniformLocationMap) {
    if (this.lut) {
      const lutTexture = gl.createTexture();
      gl.activeTexture(gl.TEXTURE1);
      gl.bindTexture(gl.TEXTURE_2D, lutTexture);
      gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          this.lut
      );

      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

      gl.activeTexture(gl.TEXTURE0);

      gl.uniform1i(uniformLocations.uLut, 1);
    }
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }

  static fabricImageToImageData(fabricImage: FabricImage): ImageData {
    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');

    if (!tempContext) {
      throw new Error('Failed to get 2D context from canvas');
    }

    tempCanvas.width = fabricImage.width;
    tempCanvas.height = fabricImage.height;

    tempContext.drawImage(fabricImage.getElement() as HTMLImageElement, 0, 0);
    const imageData = tempContext.getImageData(0, 0, tempCanvas.width, tempCanvas.height);

    return imageData;
  }

}
