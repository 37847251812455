import { filters, Canvas, FabricImage } from "fabric";
import { PassThroughFilter } from './fabric_filters/pass_through_filter';
import { PowerSaxFilter } from './fabric_filters/power_sax_filter';
import { TwoStripFilter } from './fabric_filters/two_strip_filter';
import { PurpleToneFilter } from './fabric_filters/purple_tone_filter';
import { SaxFlashFilter } from './fabric_filters/sax_flash_filter';
import { SaxPartyFilter } from './fabric_filters/sax_party_filter';
import { LutFilter } from './fabric_filters/lut_filter';


const MAX_IMAGE_SIZE = 2048;
let fabricCanvas = null,
    container = null,
    containerWidth = null,
    containerHeight = null,
    ssmFabricImage = null,
    backgroundFabricImage = null,
    lutImageData = null,
    imageLoaderLabel = null;

const Filters = {
  none: null,
  powersax: null,
  saxflash: null,
  saxparty: null,
  purpletone: null
};

const PoseUrls = {
  powersax: "/SSM/SSM__Power_Sax.png",
  saxflash: "/SSM/SSM__Sax_Flash.png",
  saxparty: "/SSM/SSM__Sax_Party.png",
};

const Poses = {
  powersax: null,
  saxflash: null,
  saxparty: null,
}

let currentFilter = null;
let currentPose = null;

// workaround so that when we change the sax man, he switches out in place instead of resetting size, location, etc
const ssmSavedCoordinates = {
  left: 40,
  top: 40,
  angle: 0,
  scaleX: 1,
  scaleY: 1
}

document.addEventListener("DOMContentLoaded", async () => {
  const fabricContainer = document.querySelector("#fabricContainer");
  const canvasElement = document.getElementById("fabricCanvas");
  const imageLoader = document.getElementById("imageLoader");
  const downloadButton = document.getElementById("downloadButton");
  const copyButton = document.getElementById("copyButton");
  const pasteButton = document.getElementById("pasteButton");
  const poseButtons = document.querySelectorAll("button.pose");
  const filterButtons = document.querySelectorAll("button.filter");
  imageLoaderLabel = document.querySelector("#fabricContainer label");

  Poses.powersax = await FabricImage.fromURL(PoseUrls['powersax']);
  Poses.saxflash = await FabricImage.fromURL(PoseUrls['saxflash']);
  Poses.saxparty = await FabricImage.fromURL(PoseUrls['saxparty']);

  Filters.none = await PassThroughFilter.create();
  Filters.powersax = await PowerSaxFilter.create();
  Filters.purpletone = await PurpleToneFilter.create();
  Filters.saxflash = await SaxFlashFilter.create();
  Filters.saxparty = await SaxPartyFilter.create();

  currentFilter = Filters.powersax;
  currentPose = Poses.saxparty;

  initSexySaxMan();


  // Change the button text if not on a desktop
  if (detectDevice() !== "Desktop") {
    downloadButton.textContent = "Download To Files App";
  }

  if (navigator.clipboard && navigator.clipboard.write) {
    try {

      if (detectDevice() !== "Desktop") {
        // see if it really works on mobile
        await navigator.clipboard.writeText("memezoid.xyz");
      }

      copyButton.addEventListener("click", copyCanvasToClipboard);
      // Handle Ctrl+C or Cmd+C keyboard events
      document.addEventListener("keydown", (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === "c") {
          copyCanvasToClipboard();
        }
      });

      // Attach the paste event listener to the document
      pasteButton.addEventListener("click", handlePaste);
      document.addEventListener("paste", handlePaste);

      copyButton.classList.remove("hidden");
      pasteButton.classList.remove("hidden");
    } catch (err) {
      console.log(`Clipboard stuff not supported: ${err}`);
    }
  }

  // Usage example
  downloadButton.addEventListener('click', () => {
    exportCanvasAsImage(fabricCanvas);
  });

  poseButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      const pose = event.currentTarget.dataset.pose;
      setPose(pose);
    });
  });

  filterButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      const filter = event.currentTarget.dataset.filter;
      setFilter(filter);
    });
  });

  container = canvasElement.parentElement;
  containerWidth = container.clientWidth;
  containerHeight = container.clientHeight;

  // if (fabricContainer && imageLoader) {
  //   fabricContainer.addEventListener("click", handleFabricContainerClick);
  //   fabricContainer.addEventListener("touchstart", handleFabricContainerClick);
  // }

  if (canvasElement && imageLoader) {
    fabricCanvas = new Canvas(canvasElement);
    fabricCanvas.setWidth(containerWidth);
    fabricCanvas.setHeight(containerHeight);

    const options = { 
      crossOrigin: 'anonymous'
    };

    initSexySaxMan();

    imageLoader.addEventListener("change", async (event) => {
      const file = event.target.files[0];

      if (!file) {
        console.error("No file selected!");
        return;
      }

      if (!file.type.startsWith("image/")) {
        console.error("Selected file is not an image!");
        return;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const dataUrl = e.target.result;
        loadBackgroundImage(fabricCanvas, dataUrl);
      };

      reader.onerror = (err) => {
        console.error("Error reading file:", err);
      };

      reader.readAsDataURL(file);
    });
  } else {
    console.error("Canvas or file input element not found!");
  }
});

function handleFabricContainerClick(event) {
  event.preventDefault(); // Prevent default behavior
  event.stopPropagation(); // Stop duplicate triggering
  imageLoader.click();
}

async function setPose(name) {
  currentPose= Poses[name];
  await initSexySaxMan();
  redraw(fabricCanvas);
}

async function setFilter(name) {
  currentFilter = Filters[name];
  redraw(fabricCanvas);
}

async function initSexySaxMan() {
  // init Sexy Sax Man
  ssmFabricImage = currentPose;
  ssmFabricImage.lockRotation = false;
  ssmFabricImage.flipX = false;
  ssmFabricImage.lockScalingFlip = true;
  ssmFabricImage.hasControls = true;
  ssmFabricImage.transparentCorners = false;
  ssmFabricImage.scaleToWidth(containerWidth * 0.3);
  ssmFabricImage.centeredScaling = true;
  ssmFabricImage.cornerStyle = "circle";
  ssmFabricImage.borderColor = 'rgb(0, 255, 0)';
  ssmFabricImage.cornerColor = 'rgb(0, 255, 0)';
  ssmFabricImage.cornerStrokeColor = 'rgb(0, 255, 0)';
  ssmFabricImage.cornerSize = 20;
  ssmFabricImage.setControlsVisibility({
    mt: false, // Top middle
    mb: false, // Bottom middle
    ml: false, // Middle left
    mr: false, // Middle right
  });
  await ssmFabricImage.applyFilters();

  ssmFabricImage.on('modified', () => {
    assignSsmCoords(ssmFabricImage);
  });
}

async function redraw(fabricCanvas) {
  if (!backgroundFabricImage) return;

  backgroundFabricImage.filters = [currentFilter];
  await backgroundFabricImage.applyFilters();

  ssmFabricImage.filters = [currentFilter];
  await ssmFabricImage.applyFilters();

  fabricCanvas.clear();

  fabricCanvas.add(backgroundFabricImage);
  
  ssmFabricImage.setCoords();

  const { left, top, angle, scaleX, scaleY } = ssmSavedCoordinates;

  ssmFabricImage.left = left;
  ssmFabricImage.top = top;
  ssmFabricImage.angle = angle;
  ssmFabricImage.scaleX = scaleX;
  ssmFabricImage.scaleY = scaleY;

  fabricCanvas.add(ssmFabricImage);
  fabricCanvas.setActiveObject(ssmFabricImage);

  fabricCanvas.renderAll();
}

async function loadBackgroundImage(fabricCanvas, dataUrl) {
  try {
    imageLoaderLabel.remove();
    // fabricContainer.removeEventListener("click", handleFabricContainerClick);
    // fabricContainer.removeEventListener("touchstart", handleFabricContainerClick);

    const resizedDataUrl = await resizeImageToMaxSize(dataUrl, MAX_IMAGE_SIZE);

    backgroundFabricImage = await FabricImage.fromURL(resizedDataUrl);

    backgroundFabricImage.hasControls = false;
    backgroundFabricImage.hasBorders = false;
    backgroundFabricImage.selectable = false;

    // Calculate the scaling factor to fit within the container
    const scaleFactor = Math.min(
      containerWidth / backgroundFabricImage.width,
      containerHeight / backgroundFabricImage.height,
      1 // Prevent upscaling
    );

    const scaledWidth = backgroundFabricImage.width * scaleFactor;
    const scaledHeight = backgroundFabricImage.height * scaleFactor;

    backgroundFabricImage.scaleToWidth(scaledWidth);
    backgroundFabricImage.scaleToHeight(scaledHeight);

    fabricCanvas.setWidth(scaledWidth);
    fabricCanvas.setHeight(scaledHeight);

    ssmFabricImage.left = 40;
    ssmFabricImage.top = 40;
    ssmFabricImage.angle = 0;
    ssmFabricImage.scaleToWidth(scaledWidth * 0.3);

    assignSsmCoords(ssmFabricImage);

    console.log(
      `Canvas resized to fit the container with dimensions: ${scaledWidth} x ${scaledHeight}`
    );

    await redraw(fabricCanvas);

  } catch (err) {
    console.error("Error loading background image:", err);
  }
}

function assignSsmCoords(ssmFabricImage) {
  ssmSavedCoordinates.left = ssmFabricImage.left;
  ssmSavedCoordinates.top = ssmFabricImage.top;
  ssmSavedCoordinates.angle = ssmFabricImage.angle;
  ssmSavedCoordinates.scaleX = ssmFabricImage.scaleX;
  ssmSavedCoordinates.scaleY = ssmFabricImage.scaleY;

  // console.log(ssmSavedCoordinates)
}

function isPortrait(height, width) {
  return height > width;
}

function isLandscape(height, widdth) {
  return width > height;
}

// if too big, I was running into texture size limits in WebGL
function resizeImageToMaxSize(url, maxSize) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const longestSide = Math.max(img.width, img.height);

      // If the image is already smaller than or equal to maxSize, resolve with the original URL
      if (longestSide <= maxSize) {
        setContainerSize(img.width, img.height);
        resolve(url);
        return;
      }

      const tempCanvas = document.createElement('canvas');
      const ctx = tempCanvas.getContext('2d');

      // Calculate scaling factor
      const scaleFactor = maxSize / Math.max(img.width, img.height);
      tempCanvas.width = img.width * scaleFactor;
      tempCanvas.height = img.height * scaleFactor;

      setContainerSize(tempCanvas.width, tempCanvas.height);

      // Draw resized image
      ctx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height);

      // Resolve with the resized image's data URL
      resolve(tempCanvas.toDataURL());
    };

    img.onerror = (error) => {
      reject(new Error('Failed to load the image: ' + error.message));
    };

    img.src = url;
  });
}

function convertCanvasToDataUrl(fabricCanvas, multiplier=2, format='jpg', quality=0.8) {
  fabricCanvas.renderOnAddRemove = false; // Disable re-rendering during updates
  const objects = fabricCanvas.getObjects();

  objects.forEach((obj) => {
    obj._originalControls = {
      hasControls: obj.hasControls,
      selectable: obj.selectable,
    };
    obj.hasControls = false; // Hide controls
    obj.selectable = false;  // Disable selection
  });

  // Generate the data URL
  const dataURL = fabricCanvas.toDataURL({
    format: format,
    quality: quality,
    multiplier: multiplier, // Increase resolution if needed
  });

  // Restore original control visibility
  objects.forEach((obj) => {
    obj.hasControls = obj._originalControls.hasControls;
    obj.selectable = obj._originalControls.selectable;
    delete obj._originalControls;
  });

  fabricCanvas.renderOnAddRemove = true; // Re-enable rendering
  fabricCanvas.renderAll(); // Ensure the canvas is rendered correctly

  return dataURL;
}

function exportCanvasAsImage(fabricCanvas, filename = 'Sexy Sax Man + Me.jpg') {
  // Trigger download
  const link = document.createElement('a');
  link.href = convertCanvasToDataUrl(fabricCanvas,  getExportMultiplier(fabricCanvas)); //dataURL;
  link.download = filename;
  link.click();
}

// try to export so longest size is 2048
function getExportMultiplier(fabricCanvas, targetSize = MAX_IMAGE_SIZE) {
  const { width, height } = fabricCanvas;
  const longestSide = Math.max(width, height);
  const multiplier = targetSize / longestSide;

  return multiplier;
}

function setContainerSize(imageFullWidth, imageFullHeight) {
  // Calculate the maximum allowable dimensions
  const vw96 = Math.min(window.innerWidth * 0.96, MAX_IMAGE_SIZE);  // Maximum width is 96% of viewport width or 2048px, whichever is smaller
  const vh80 = window.innerHeight * 0.80;                           // Maximum height (80% of viewport height)

  // Initialize target width and height
  containerWidth = imageFullWidth;
  containerHeight = imageFullHeight;

  console.log('containerWidth', containerWidth, 'containerHeight', containerHeight);

  // Check if the image is too wide
  if (containerWidth > vw96) {
    // Scale down proportionally
    const scaleFactor = vw96 / containerWidth;
    containerWidth = vw96;
    containerHeight = containerHeight * scaleFactor;
  }

  // Check if the image is too tall after width adjustment
  if (containerHeight > vh80) {
    // Scale down proportionally
    const scaleFactor = vh80 / containerHeight;
    containerHeight = vh80;
    containerWidth = containerWidth * scaleFactor;
  }

  // Apply the calculated dimensions to the container
  container.style.width = `${containerWidth}px`;
  container.style.height = `${containerHeight}px`;

  console.log(`Container size set to: ${containerWidth}px x ${containerHeight}px`);
}

function detectDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPhone/i.test(userAgent)) {
    return "iPhone";
  }

  if (/Android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
    return "iPad";
  }

  return "Desktop";
}

async function handlePaste(event) {
  try {
    // Case 1: Check for `event.clipboardData` (paste event)
    const clipboardData = event?.clipboardData || window.clipboardData;
    if (clipboardData) {
      const items = clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.startsWith("image/")) {
          const file = items[i].getAsFile();
          const url = URL.createObjectURL(file);
          loadBackgroundImage(fabricCanvas, url); // Call your custom function
          return; // Exit after processing an image
        }
      }
      console.log("No image found in clipboard.");
      return;
    }

    // Case 2: Use the Clipboard API for asynchronous clipboard access
    const clipboardItems = await navigator.clipboard.read(); // Read clipboard items
    for (const item of clipboardItems) {
      if (item.types.includes("image/png") || item.types.includes("image/jpeg")) {
        const blob = await item.getType("image/png"); // Adjust for image type
        const url = URL.createObjectURL(blob);
        loadBackgroundImage(fabricCanvas, url);
        return;
      }
    }
    console.log("No image found in clipboard. Please copy an image and try again.");
  } catch (err) {
    console.error("Error accessing clipboard:", err);
    alert(`Try Ctrl+V or Cmd+V or change your browser permissions. ${err}`);
  }
}

// Function to copy the canvas to the clipboard
async function copyCanvasToClipboard() {
  try {
    const dataUrl = convertCanvasToDataUrl(fabricCanvas,  getExportMultiplier(fabricCanvas), 'png');

    const response = await fetch(dataUrl);
    const blob = await response.blob();

    // Copy the Blob to the clipboard
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);

    console.log("Canvas copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy canvas:", err);
    alert("Copying to clipboard is not supported on this device or browser.");
  }
}

