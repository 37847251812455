// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';


const fragmentSource = `
  precision mediump float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  void main()
  {           
    vec4 x, y;
    
    vec4 color = texture2D(uTexture, vTexCoord);
    gl_FragColor = color;
  }
`

type PassThroughFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: PassThroughFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class PassThroughFilter extends filters.BaseFilter<'PassThroughFilter', PassThroughFilterProps> {

  static type = 'PassThroughFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "";

  declare lut: PassThroughFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  /**
   * A static async factory method to create an instance of PassThroughFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<PassThroughFilter> {
    // Create and return an instance of the filter
    return new PassThroughFilter();
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }

  /**
   * Apply the PassThroughFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    console.log('applyTo2d')
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }

  isNeutralState(): boolean {
    return true;    // don't try to render
  }

}
