// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';
import { LutFilter } from './lut_filter';

const fragmentSource = `
  precision mediump float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  float bias(float a, float b) {
    return a/((1.0/b-2.0)*(1.0-a)+1.0);
  }

  float gain(float a, float b) {
    float c = (1.0/b-2.0) * (1.0-2.0*a);
    if (a < 0.5)
      return a/(c+1.0);
    else
      return (c-a)/(c-1.0);
  }

  void main()
  {           
    vec4 x, y;
    
    vec4 color = texture2D(uTexture, vTexCoord);
    x = color;
    
    vec3 Pr = vec3(0.250557133437350, 0.748442866562650, 1.0);
    vec3 Pg = vec3(0.150539062073583, 0.848460937926417, 1.0);
    vec3 Pb = vec3(0.419025266390667, 1.216269165249610, -0.637294431640273);
    
    y.r = Pr.x + x.r * Pr.y;
    y.g = Pg.x + x.g * Pg.y;
    y.b = Pb.x + x.b * (Pb.y + x.b * Pb.z);
    y.w = x.w;
    
    if(x.g < 0.12)
    {
        y.g = 0.25235;
    }
    
    float brightness = 0.9;
    float contrast = 1.55;
    
    y.rgb = y.rgb*brightness;
    y.rgb = (y.rgb-0.5)*contrast+0.5;
    
    float gainf = 0.55;
    float biasf = 0.45;
    
    y.r = gain(y.r, gainf);
    y.r = bias(y.r, biasf);
    
    y.b = gain(y.r, gainf);
    y.b = bias(y.g, biasf);
    
    gl_FragColor = y;
  }
 `

type TwoStripFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: TwoStripFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class TwoStripFilter extends filters.BaseFilter<'TwoStripFilter', TwoStripFilterProps> {

  static type = 'TwoStripFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "/LUT/HALD64_SSM_Two_Strip.png";

  declare lut: TwoStripFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: TwoStripFilterProps = filterDefaultValues) {
    super(options);

    if (TwoStripFilter.lutImageData) {
      this.lut = TwoStripFilter.lutImageData;
    } else {
      throw new Error(
        'LUT is not loaded. Use TwoStripFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of TwoStripFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<TwoStripFilter> {
    // Load LUT if not already loaded
    if (!this.lutImageData) {
      const lutImage = await FabricImage.fromURL(TwoStripFilter.HALD_URL);
      this.lutImageData = LutFilter.fabricImageToImageData(lutImage);
      console.log('Two Strip LUT loaded successfully.');
    }

    // Create and return an instance of the filter
    return new TwoStripFilter();
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }

  /**
   * Apply the TwoStripFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    LutFilter.applyHaldLut(pixels, this.lut, TwoStripFilter.HALD_URL);
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }
}
