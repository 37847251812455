// Boilerplate filter code https://github.com/fabricjs/fabric.js/blob/master/src/filters/Boilerplate.ts

import { filters, Color, FabricImage, type T2DPipelineState } from 'fabric';
import { LutFilter } from './lut_filter';


const fragmentSource = `
  precision mediump float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  void main()
  {           
    vec4 x, y;
    
    vec4 color = texture2D(uTexture, vTexCoord);
    x = color;
      
    vec4 Pr = vec4(0.378033356476416,  -1.071018244278537,   4.425771317278541,  -2.875342710462280);
    vec4 Pg = vec4(0.049956604325350,   0.388463362738195,   2.299050444435158,  -1.878952518276580);
    vec4 Pb = vec4(0.234558755144635,   0.480394792879535,   0.269978708766412,  -0.287726151677198);
      
    y.r = Pr.x + x.r * (Pr.y + x.r * (Pr.z + x.r * Pr.w));
    y.g = Pg.x + x.g * (Pg.y + x.g * (Pg.z + x.g * Pg.w));
    y.b = Pb.x + x.b * (Pb.y + x.b * (Pb.z + x.b * Pb.w));    
    y.w = x.w;
      
    gl_FragColor = y;
  }
`

type SaxPartyFilterProps = {
  lut: ImageData | null;
};

const filterDefaultValues: SaxPartyFilterProps = {
  lut: null,
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}


export class SaxPartyFilter extends filters.BaseFilter<'SaxPartyFilter', SaxPartyFilterProps> {

  static type = 'SaxPartyFilter';

  static lutImageData: ImageData | null = null;

  static HALD_URL = "/LUT/HALD64_SSM_Sax_Party.png";

  declare lut: SaxPartyFilterProps['lut'];

  static defaults = filterDefaultValues;

  static uniformLocations = ['uLut'];

  constructor(options: SaxPartyFilterProps = filterDefaultValues) {
    super(options);

    if (SaxPartyFilter.lutImageData) {
      this.lut = SaxPartyFilter.lutImageData;
    } else {
      throw new Error(
        'LUT is not loaded. Use SaxPartyFilter.create() to ensure proper initialization.'
      );
    }
  }

  /**
   * A static async factory method to create an instance of SaxPartyFilter.
   * This method ensures the LUT is loaded before creating the instance.
   */
  static async create(): Promise<SaxPartyFilter> {
    // Load LUT if not already loaded
    if (!this.lutImageData) {
      const lutImage = await FabricImage.fromURL(SaxPartyFilter.HALD_URL);
      this.lutImageData = LutFilter.fabricImageToImageData(lutImage);
      console.log('Sax Party LUT loaded successfully.');
    }

    // Create and return an instance of the filter
    return new SaxPartyFilter();
  }

  protected getFragmentSource(): string {
    return fragmentSource;
  }

  /**
   * Apply the SaxPartyFilter operation to a Uint8ClampedArray representing the pixels of an image.
   *
   * @param {Object} options
   * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
   */
  applyTo2d({ imageData: { data: pixels } }: T2DPipelineState) {
    LutFilter.applyHaldLut(pixels, this.lut, SaxPartyFilter.HALD_URL);
  }
  
  
  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(
    gl: WebGLRenderingContext,
    uniformLocations: TWebGLUniformLocationMap,
  ) {
  }

  static async fromObject(object: any): Promise<MyFilter> {
    // or overide with custom logic if your filter needs to
    // deserialize something that is not a plain value
    return new this(object);
  }
}
